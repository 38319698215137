import { Link } from "gatsby";
import * as React from "react";

import { HOVER_TRANSITION } from "../../pages/owner-since";

export default function Button({
  light,
  fluid,
  children,
  to,
  extraClasses = [],
  ...props
}) {
  const colors = light
    ? "bg-white-900 text-colors-outer-slate"
    : "bg-colors-outer-slate text-white-900";
  const width = fluid ? "w-full" : "";

  const CTA = to ? Link : "button";
  const extraClassNames = extraClasses.join(" ");
  const classNames = `${colors} ${HOVER_TRANSITION} ${width} text-center inline-block text-xs px-6 py-2.5 leading-6 uppercase rounded-sm font-bold md:text-sm md:py-5 md:text-sm ${extraClassNames}`;
  return (
    <CTA to={to} className={classNames} {...props}>
      {children}
    </CTA>
  );
}
