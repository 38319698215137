import React from "react";

import Button from "./button";
import plantSvg from "../../images/owner-since/plant.svg";

export default function LearnMore() {
  return (
    <div className="content-container-max lg:mt-16 lg:pt-1.5">
      <div className="bg-blue-900 py-10 bg-no-repeat bg-right-bottom mt-14 lg:py-32 relative">
        <div className="content-container text-white-900 sm:flex relative z-10">
          <div className="px-2 sm:px-0 sm:w-1/2 sm:flex sm:items-center">
            <div>
              <h2 className="text-xl sm:text-3xl lg:text-4xl xl:text-6xl">
                Want to learn more?
              </h2>
              <p className="text-sm mt-1 sm:text-lg lg:text-2xl md:mt-6 sm:mb-14">
                We're here to help answer your questions.
              </p>
              <div className="hidden sm:block">
                <Button to="/owner-since/contact-specialist" light>
                  Chat with a Product Specialist
                </Button>
              </div>
            </div>
          </div>

          <div className="px-2 sm:px-0 sm:w-1/2 sm:flex sm:items-center xl:justify-end">
            <ul className="mt-5 sm:text-xl md:text-2xl xl:text-4xl lg:mt-0">
              <li className="mb-1 sm:mb-6 md:mb-12">- Patented protection</li>
              <li className="mb-1 sm:mb-6 md:mb-12">
                - Best-in-class warranties
              </li>
              <li>- Risk-free home trial</li>
            </ul>
          </div>

          <div className="px-2 mt-7 sm:hidden">
            <Button to="/owner-since/contact-specialist" light>
              Chat with a Product Specialist
            </Button>
          </div>
        </div>
        <img
          className="absolute right-0 bottom-0 w-28 md:w-auto z-0"
          src={plantSvg}
        />
      </div>
    </div>
  );
}
