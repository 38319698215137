import Img from "gatsby-image";
import React from "react";
import Button from "./button";
import { HOVER_TRANSITION } from "../../pages/owner-since";
import playSvg from "../../images/owner-since/play.svg";
import WistiaVideoPopoverLink from "./wistia-video-popover-link";

export default function VideoCallout({
  image,
  imageFirst = true,
  location,
  ownerName,
  ownerSinceYear,
  quote,
  wistiaVideoId,
  videoCtaText,
}) {
  return (
    <div className="mb-20 pb-1 sm:flex lg:mb-32 lg:pb-5">
      <div
        className={`sm:w-1/2 ${imageFirst ? "sm:pr-6" : "sm:pl-6"}`}
        style={{ order: imageFirst ? 1 : 2 }}
      >
        <div
          className={`relative ${
            imageFirst
              ? "image-drop-one -mr-5 ml-2.5 pl-1 sm:pl-0 sm:mr-0 sm:ml-0"
              : "image-drop-two -ml-5 mr-2.5 pr-1 sm:pr-0 sm:ml-0 sm:mr-0"
          }`}
        >
          <WistiaVideoPopoverLink videoId={wistiaVideoId}>
            <div className={`cursor-pointer ${HOVER_TRANSITION}`}>
              <img alt={ownerName} src={image.src} />
              <button
                className={`absolute items-center flex text-blue-900 text-sm ${
                  imageFirst ? "-left-3" : "left-5"
                } -bottom-11 lg:text-lg sm:left-0`}
              >
                <img className="mr-2 w-3 md:w-auto" src={playSvg} />{" "}
                {videoCtaText}
              </button>
            </div>
          </WistiaVideoPopoverLink>
        </div>
      </div>

      <div
        className={`sm:w-1/2 flex items-center mt-16 pt-2 sm:pt-0 sm:mt-0  ${
          imageFirst ? "sm:pl-6 md:pl-24" : "sm:pr-6 md:pr-24"
        }`}
        style={{ order: imageFirst ? 2 : 1 }}
      >
        <div>
          <h2 className="text-blue-500 lg:text-lg">
            Outer Owner Since {ownerSinceYear}
          </h2>
          <blockquote className="text-blue-900 text-xl leading-tight mt-5 mb-5 lg:my-12 lg:leading-tight lg:text-4xl">
            "{quote}"
          </blockquote>
          <p className="divide-x divide-blue-900 text-sm  md:text-lg mb-7 lg:mb-16 lg:text-2xl">
            <span className="text-blue-900 mr-2 lg:mr-6">{ownerName}</span>
            <span className="text-blue-500 pl-2 lg:pl-6">{location}</span>
          </p>
          <Button to="/owner-since/contact">Chat with a customer</Button>
        </div>
      </div>
    </div>
  );
}
