import React from "react";

// make sure wistia is installed so we can load the popovers w/ custom code
export function requireWistia() {
  if (!document.getElementById("wistia_script")) {
    const wistiaScript = document.createElement("script");
    wistiaScript.id = "wistia_script";
    wistiaScript.type = "text/javascript";
    wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js";
    wistiaScript.async = true;
    document.body.appendChild(wistiaScript);
  }
}

export default function WistiaVideoPopoverLink({ children, videoId }) {
  React.useEffect(() => {
    requireWistia();
  }, []);

  return (
    <span
      className={`wistia_embed wistia_async_${videoId} popover=true popoverContent=link`}
    >
      {children}
    </span>
  );
}
