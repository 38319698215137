import Img from "gatsby-image";
import React from "react";

import blueUnderline from "../../images/owner-since/underline-blue.png";
import underlineSvg from "../../images/owner-since/underline.svg";

export default function Hero({ data }) {
  return (
    <div className="relative text-center text-blue-900 lg:text-white-900">
      <Img className="darken-image" fluid={data.hero.childImageSharp.sizes} />

      <div className="mt-7 lg:mt-0 lg:absolute lg:-inset-0 lg:flex lg:flex-col">
        <div className="content-container">
          <h1 className="text-4xl lg:text-5xl xl:text-7xl lg:mt-12 2xl:mt-28">
            Premium Outdoor Furniture, <br />
            Built to Last.
          </h1>
          <div className="lg:w-5/12 lg:mx-auto">
            <p className="text-lg mt-2 lg:mt-8 lg:leading-normal lg:text-2xl">
              Hear from real customers who have owned their Outer Sofa for{" "}
              <strong className="year-underline relative">
                years.
                <span className="absolute -bottom-2 left-0 right-2 lg:-bottom-3 lg:right-0">
                  <img src={blueUnderline} className="lg:hidden" />
                  <img src={underlineSvg} className="hidden lg:block" />
                </span>
              </strong>
            </p>
          </div>
        </div>

        <div className="content-container-max flex-col flex sm:flex-row sm:my-14 items-center lg:mt-auto">
          <div className="w-1/3 mt-10 sm:mt-0">
            <p className="text-xl xl:text-4xl mb-5 sm:mb-3 xl:mb-6">
              As seen in:
            </p>

            <Img
              alt="Curbed"
              className="w-16 mx-auto lg:w-screen"
              style={{ maxWidth: 86 }}
              fluid={[
                data.curbedSm.childImageSharp.fluid,
                {
                  ...data.curbedLg.childImageSharp.fluid,
                  media: `(min-width: 1024px)`,
                },
              ]}
            />
          </div>
          <div className="w-1/3 mt-9 sm:mt-2 lg:px-11 lg:box-content">
            <Img
              alt="5 Stars"
              className="w-20 mx-auto mb-4 sm:mb-6 lg:w-screen xl:mb-10"
              style={{ maxWidth: 124.5 }}
              fluid={[
                data.starsSm.childImageSharp.fluid,
                {
                  ...data.starsLg.childImageSharp.fluid,
                  media: `(min-width: 1024px)`,
                },
              ]}
            />

            <p className="text-md md:text-xl">250+ Reviews</p>
          </div>
          <div className="w-1/3 mt-10 sm:mt-0">
            <p className="text-xl xl:text-4xl md:mb-4 xl:mb-7">As seen in:</p>

            <Img
              alt="GQ"
              className="w-16 mx-auto mt-3 mb-10 sm:w-screen sm:mb-0 lg:mt-0"
              style={{ maxWidth: 63.5 }}
              fluid={[
                data.gqSm.childImageSharp.fluid,
                {
                  ...data.gqLg.childImageSharp.fluid,
                  media: `(min-width: 1024px)`,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
