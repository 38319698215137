import Img from "gatsby-image";
import React from "react";

import Button from "./button";

export default function AcrossTheCountry({ imageData }) {
  return (
    <div className="lg:pt-2">
      <h3 className="tracking-wide sm:tracking-normal text-blue-900 text-2xl text-center md:mx-auto md:max-w-xl lg:max-w-4xl lg:text-6xl">
        Join thousands of proud Outer owners from across the country
      </h3>
      <div className="text-center mt-8 mb-16 pb-2 lg:mt-10 lg:pt-1 lg:mb-24">
        <Button to="/owner-since/contact">Chat with a customer</Button>
      </div>
      <Img
        className="md:mx-auto md:max-w-xl lg:max-w-4xl"
        fluid={imageData}
        alt="Outer Map"
      />
    </div>
  );
}
