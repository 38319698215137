import React from "react";
import { graphql } from "gatsby";

import AcrossTheCountry from "../../components/owner-since/across-the-country";
import Hero from "../../components/owner-since/hero";
import Layout from "../../components/shared/layout-light";
import LearnMore from "../../components/owner-since/learn-more";
import VideoCallout from "../../components/owner-since/video-callout";

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "owner-since/hero.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 1240) {
          ...GatsbyImageSharpSizes
        }
      }
    }

    map: file(relativePath: { eq: "owner-since/us-map.png" }) {
      childImageSharp {
        sizes(maxWidth: 1240) {
          ...GatsbyImageSharpSizes
        }
      }
    }

    autumn: file(relativePath: { eq: "owner-since/customer-autumn-s.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1008) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    mary: file(relativePath: { eq: "owner-since/customer-mary-m.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    marissa: file(relativePath: { eq: "owner-since/customer-marissa-k.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    curbedSm: file(relativePath: { eq: "owner-since/curbed-sm.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    curbedLg: file(relativePath: { eq: "owner-since/curbed-white.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    starsSm: file(relativePath: { eq: "owner-since/stars-sm.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    starsLg: file(relativePath: { eq: "owner-since/stars-white.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gqSm: file(relativePath: { eq: "owner-since/gq-sm.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gqLg: file(relativePath: { eq: "owner-since/gq-white.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export const HOVER_TRANSITION =
  "hover:opacity-75 focus:opacity-50 transition duration-300";

const OwnerSinceFunnelPage = function ({ data }) {
  return (
    <Layout homeUrl="/owner-since">
      <Hero data={data} />
      <div className="content-container mt-10 lg:mt-36 lg:pt-2">
        <VideoCallout
          image={data.autumn.childImageSharp.fluid}
          location="California"
          wistiaVideoId="ckj895uv6e"
          videoCtaText="Watch coffee stains come right out"
          ownerName="Autumn S."
          ownerSinceYear="2018"
          quote="We’re still enjoying the Outer life, 3 years later! Our set has held up beautifully through all of the playdates!"
        />

        <VideoCallout
          image={data.mary.childImageSharp.fluid}
          imageFirst={false}
          location="Pennsylvania"
          wistiaVideoId="elvtvu8mpt"
          videoCtaText="Durable All-Weather Wicker"
          ownerName="Mary M"
          ownerSinceYear="2019"
          quote="Outer did not disappoint. The furniture is comfortable and durable to stand up to the weather and three small children."
        />

        <VideoCallout
          image={data.marissa.childImageSharp.fluid}
          location="South Carolina"
          wistiaVideoId="mbbqzbp0jm"
          videoCtaText="Real backyard inspiration"
          ownerName="Marissa K"
          ownerSinceYear="2020"
          quote="We bought the gray sectional and it has been great. We have two young children and a dog. It has held up great so far. It’s more comfortable than our indoor couches."
        />

        <AcrossTheCountry imageData={data.map.childImageSharp.sizes} />
      </div>

      <LearnMore />
    </Layout>
  );
};

export default OwnerSinceFunnelPage;
